.navbar{
    padding: 1.8rem 0;
    background-color: var(--black-color);
}
.navbar-brand{
    width: 180px;
    margin: auto;
}
.navbar-brand span{
    margin-left: 2rem;
}
.brand-and-toggler{
    width: 40%;
}
.navbar-content{
    display: flex;
    width: 100%;
}
.navbar-toggler-btn{
    transition: var(--transition);
    z-index: 11;
}
.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: var(--black-color);
    height: 100%;
    width: 280px;
    display: flex;
    padding: 10rem 3rem 0 3rem;
    transform: translatex(100%);
    transition: var(--transition);
}
.show-navbar-collapse{
    transform: translateX(0);
}
.nav-item{
    margin-bottom: 1.4rem;
}
.nav-link{
    transition: var(--transition);
    color: white;
}
.nav-link:hover{
    font-weight: 700;
}

@media screen and (min-width: 992px){
    .navbar-collapse{
        position: relative;
        height: auto;
        padding: 0;
        width: 100%;
        background-color: transparent;
        transform: translateX(0);
        justify-content: flex-end;
    }
    .navbar-toggler-btn{
        display: none;
    }
    .nav-item{
        margin-bottom: 0;
        margin-left: 2.8rem;
    }
    .nav-link{
        color: white;
        font-size: 1.4rem;
        font-weight: 500!important;
        opacity: 0.8;
    }
    .navbar-nav{
        display: flex;
    }
}


@media (max-width: 992px) {
    .brand-and-toggler {
        width: 100%;
    }
    .navbar-brand {
        width: 250px;
    }
}